import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const About = (props) => {
  return (
    <Layout bodyClass="page-teams">
      <SEO title="About" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-6">
              <h1>About</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="col-8">
          <h4 className="mt-4 mb-2">会社概要</h4>
          <table className="table">
            <tbody>
              <tr>
                <td className="field font-weight-bold">会社名</td>
                <td className="value">株式会社Lachsy（ラクシー）</td>
              </tr>
              <tr>
                <td className="field font-weight-bold">代表者</td>
                <td className="value">代表取締役 上田 雄登</td>
              </tr>
              <tr>
                <td className="field font-weight-bold">本社所在地</td>
                <td className="value">東京都新宿区四谷坂町３－１７</td>
              </tr>
              <tr>
                <td className="field font-weight-bold">設立</td>
                <td className="value">2020年1月15日</td>
              </tr>
              <tr>
                <td className="field font-weight-bold">資本金</td>
                <td className="value">100万円</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default About;
